@import url('https://fonts.googleapis.com/css2?family=Maven+Pro:wght@400;500;600;700;800;900&display=swap');

body {
  font-family: 'Maven Pro', sans-serif;
  margin: 0;
}

*{
  margin: 0;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

/* NAVBAR START */

.menu {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: .7rem;
  background: #edeff5;
  border-bottom: 1px solid #c2c2c2;
}

.menu-logo {
  flex: 1;
  font-size: 2rem;
}

.menu-logo img{
  width: 55px;
}

.menu-items {
  flex: 10;
  display: flex;
  justify-content: center;
  list-style: none;
  margin: 0;
  padding: 0;
  transition: all 0.5s ease-in-out;
}

.menu-items a{
  text-transform: uppercase;
  text-decoration: none;
  color: #8d8d8d;
  font-size: .9em;
  font-weight: 500;
}

.menu-items .active_item{
  font-weight: 600;
  color: #000;
}

.side-menu-items{
  display: flex;
  flex-direction: column;
  align-items: center;
  list-style: none;
  margin: 0;
  padding: 0;
  transition: all 0.5s ease-in-out;
}

.side-menu-items .menu-item {
  padding: 5px;
}

.side-menu-items a{
  text-decoration: none;
  color: #8d8d8d;
  font-weight: 600;
  font-size: 1.1em;
}

.menu-item {
  margin: 0 1rem;
  cursor: pointer;
}

.menu-hamburger {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.menu-hamburger-line {
  width: 100%;
  height: 3px;
  background-color: #000;
}

.sidebar {
  position: fixed;
  top: 0;
  left: -300px;
  height: 100%;
  width: 300px;
  background-color: rgb(228, 228, 228);
  transition: all 0.5s ease-in-out;
  z-index: 2;
}

.sidebar h2 {
  font-weight: 900;
  color: #000;
  text-align: center;
}

.sidebar-open {
  left: 0;
}

.sidebar h2 {
  margin: 1rem;
}

.sidebar p {
  margin: 1rem;
}

.sidebar-toggle {
  display: none;
  cursor: pointer;
  position: relative;
  top: 1rem;
  right: 1rem;
  padding: 0.5rem;
  border: none;
  background: none;
  color: #000;
  cursor: pointer;
}

.sidebar-toggle i{
  font-size: 1.2em;
  position: relative;
  bottom: 15px;
}

@media screen and (max-width: 992px) {
  .menu-items{
    display: none;
  }

  .sidebar-toggle{
    display: block;
  }
}

/* NAVBAR END */

/* HOME PAGE START */

.homepage .section{
  padding: 30px 0 30px 0;
  box-shadow: 0px 30px 60px rgba(0, 0, 0, .1);
}

.homepage .section .intro{
  padding-left: 3em;
}

.homepage .section .intro .logo{
  font-size: 3em;
  color: #111f4d;
  font-weight: 900;
}

.homepage .section .intro .logo>b{
  color: #132a75;
}

@media (max-width: 600px) {
  .homepage .section .intro .logo{
    font-size: 2em;
  }
}

.homepage .section .intro .head_txt{
  width: 40%;
  text-transform: uppercase;
  font-size: 1.5em;
  font-weight: 800;
  line-height: 30px;
  color: #111f4d;
  padding: 5px 0;
}

.homepage .section .intro .head_txt>b{
  color: #132a75;
}

@media (max-width: 600px) {
  .homepage .section .intro .head_txt{
    width: 60%;
    font-size: 1em;
  }
}

.homepage .section .intro_txt{
  font-size: .9em;
  width: 30%;
  line-height: 25px;
  padding: 10px 0 10px 3em;
}

.homepage .section .chart{
  padding-left: 3em;
  padding-right: 3em;
}

.homepage .section .btn_links{
  display: flex;
  align-items: center;
  padding: 10px 0 10px 3em;
}
.homepage .section .btn_links button{
  text-transform: uppercase;
  font-size: .65em;
  background: #111f4d;
  color: #fff;
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  margin-right: 10px;
}

.homepage .section .btn_links .icon_links{
  display: flex;
  justify-content: space-evenly;
  border: 1px solid #111f4d;
  border-radius: 5px;
  padding: 6.5px 15px;
}

.homepage .section .btn_links .icon_links i{
  padding: 0 6.5px;
  cursor: pointer;
  color: #132a75;
}

.homepage .section .re_dir{
  width: 50%;
  display: flex;
  justify-content: space-between;
  padding: 15px 0 15px 3em;
}

.homepage .section .re_dir>span{
  font-size: 1.2em;
  font-weight: 600;
}

.homepage .section .segment .head_txt{
  color: #111f4d;
  font-size: 1.6em;
  font-weight: 700;
}

.homepage .section .segment #about{
  padding-left: 3em;
}
.homepage .section .segment #about .container .left .sub{
  color: #111f4d;
  font-size: 2.2em;
  transform: rotate(-90deg);
  text-transform: uppercase;
  font-weight: 700;
  padding: 30px;
}

.homepage .section .segment .left .head_txt{
  text-align: right;
  padding-bottom: 10px;
}

.homepage .section .segment .container{
  display: flex;
}

.homepage .section .segment .container .left .digit, .homepage .section .segment .container .right .digit{
  font-size: 6em;
  color: #111f4d;
  font-weight: 900;
}

.homepage .section .segment #vision .container .left .sub{
  color: #111f4d;
  font-size: 2.2em;
  transform: rotate(-90deg);
  text-transform: uppercase;
  font-weight: 700;
  padding: 30px;
}

.homepage .section .segment #vision .container .right .sub{
  color: #111f4d;
  font-size: 2.2em;
  transform: rotate(90deg);
  text-transform: uppercase;
  font-weight: 700;
  padding: 0px 20px 70px 0;
}

.homepage .section .segment .container .right, .homepage .section .segment .container .left{
  padding: 3em 0 3em 3em;
}

.homepage .section .segment .container .right .txt{
  color: #111f4d;
  font-size: 1.2em;
  font-weight: 700;
  padding-bottom: 10px;
}

.homepage .section .segment .container .right .paragraph{
  color: #3f3f3f;
  width: 80%;
  font-weight: 600;
  font-size: .9em;
  line-height: 30px;
}

.homepage .section .segment #vision .container .left{
  display: flex;
  padding: 6em;
}

.homepage .section .segment .container .left .paragraph{
  color: #3f3f3f;
  font-weight: 600;
  font-size: .9em;
  line-height: 30px;
  text-align: right;
}

.homepage .section .segment #vision .container .right{
  padding: 3em 6em 0 0;
}

.homepage .section .segment .container .right .paragraph>b{
  color: #132a75;
}

.homepage .section .segment .container .right .wing{
  display: flex;
  justify-content: flex-end;
}

.homepage .section .segment .container .left .wing{
  display: flex;
  justify-content: flex-start;
}

.homepage .section .segment .container .right .wing img{
  height: auto;
  width: 50%;
}

.homepage .section .segment .container .left .wing img{
  height: 15em;
  width: auto;
}

.homepage .section .segment #utility .container{
  display: flex;
  flex-direction: column;
  align-items: center;
}

.homepage .section .segment #utility .container .head{
  width: 40%;
  text-align: center;
}

.homepage .section .segment #utility .container .head .digit{
  font-size: 6em;
  color: #111f4d;
  font-weight: 900;
}

.homepage .section .segment #utility .container .head .sub{
  color: #111f4d;
  font-size: 2.2em;
  text-transform: uppercase;
  font-weight: 700;
  padding: 0px;
}

.homepage .section .segment #utility .container .head .head_txt{
  padding-bottom: 5px;
}

.homepage .section .segment #utility .container .text{
  width: 70%;
  text-align: center;
}

.homepage .section .segment #utility .container .text .paragraph{
  color: #3f3f3f;
  font-weight: 600;
  font-size: .9em;
  line-height: 30px;
}

.homepage .section .segment #utility .container button{
  background: #111f4d;
  padding: 10px;
  border: none;
  border-radius: 5px;
  margin: 10px;
}

.homepage .section .segment #utility .container button a{
  font-size: .9em;
  text-transform: uppercase;
  color: #fff;
  text-decoration: none;
}

.homepage .section .segment #utility .container .pc_destinations{
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 10px;
}

.homepage .section .segment #utility .container .pc_destinations img{
  height: 15em;
  padding-left: 6em;
}

.homepage .section .segment #utility .container .jumbo{
  padding: 6em 0;
}

.homepage .section .segment #utility .container .jumbo p{
  text-align: center;
}

.homepage .section .segment #utility .container .jumbotron{
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(23%, 1fr));
  grid-gap: 1em;
  justify-content: center;
}

@media (max-width: 1200px) {
  .homepage .section .segment #utility .container .jumbotron{
    grid-template-columns: repeat(auto-fill, minmax(40%, 1fr));
  }
}

@media (max-width: 760px) {
  .homepage .section .segment #utility .container .jumbotron{
    grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
  }
}

.homepage .section .segment #utility .container .jumbotron img{
  width: 4em;
}

.homepage .section .segment #utility .container .jumbotron .flanks{
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid #132a75;
  border-radius: 5px;
  margin: 10px;
  padding: 30px 0;
}

@media (max-width: 1200px) {
  .homepage .section .segment #utility .container .jumbotron .flanks{
    margin: 0 10px;
  }
}

.homepage .section .segment #utility .container .jumbotron .center{
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid #132a75;
  border-radius: 5px;
  margin: 0 10px;
  padding: 30px 0;
}

.homepage .section .segment #utility .container .jumbotron .flanks .title, .homepage .section .segment #utility .container .jumbotron .center .title{
  text-align: center;
  font-size: 1.1em;
  color: #111f4d;
  font-weight: 600;
  padding: 20px 0;
}

.homepage .section .segment #utility .container .jumbotron .flanks .text, .homepage .section .segment #utility .container .jumbotron .center .text{
  color: #3f3f3f;
  font-weight: 600;
  font-size: .9em;
  line-height: 20px;
}

.homepage .section .segment #roadmap .container{
  margin: 0 6em;
}

.homepage .section .segment #roadmap .container .left{
  display: flex;
  align-items: center;
}

.homepage .section .segment #roadmap .container .left p{
  width: 40%;
  text-align: left;
}

.homepage .section .segment #roadmap .container .right .sub{
  color: #111f4d;
  font-size: 2.2em;
  text-transform: uppercase;
  font-weight: 700;
}

.homepage .section .segment #roadmap .map_cont img{
  width: 100%;
}

.homepage .section .segment #roadmap .text_cont{
  margin: 0 6em;
  display: flex;
  justify-content: flex-end;
}

.homepage .section .segment #roadmap .text_cont p{
  width: 30%;
  text-align: right;
  color: #3f3f3f;
  font-weight: 600;
  font-size: .9em;
  line-height: 30px;
  text-align: right;
}

.homepage .section .segment #tokenomics .container{
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(32%, 1fr));
  grid-gap: 1em;
  justify-content: center;
}

@media (max-width: 800px) {
  .homepage .section .segment #tokenomics .container{
    grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
  }
}

.homepage .section .segment #tokenomics .container .left{
  padding: 0 3em;
}

@media (max-width: 1200px) {
  .homepage .section .segment #tokenomics .container .left{
    padding: 0 1em;
  }
}

.homepage .section .segment #tokenomics .container .left .sub{
  color: #111f4d;
  font-size: 2.2em;
  text-transform: uppercase;
  font-weight: 700;
  /*padding: 30px;*/
}

.homepage .section .segment #tokenomics .container .left .tkn_txt{
  width: 90%;
}

.homepage .section .segment #tokenomics .container .left .paragraph{
  color: #3f3f3f;
  font-weight: 600;
  font-size: .9em;
  line-height: 30px;
  text-align: left;
}

.homepage .section .segment #tokenomics .container .left .txt_sub{
  color: #3f3f3f;
  font-size: .8em;
  font-weight: 600;
  line-height: 20px;
}

.homepage .section .segment #tokenomics .container .coin{
  padding: 3em;
  display: flex;
  justify-content: center;
}

.homepage .section .segment #tokenomics .container .coin > img{
  height: 15em;
}

@media (max-width: 1200px) {
  .homepage .section .segment #tokenomics .container .coin{
    padding: 1em;
  }

  .homepage .section .segment #tokenomics .container .coin > img{
    height: 10em;
  }
}

.homepage .section .segment #tokenomics .container .right{
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 3em;
}

@media (max-width: 1200px) {
  .homepage .section .segment #tokenomics .container .right{
    padding: 1em;
  }
}

.homepage .section .segment #tokenomics .container .right .head_txt{
  display: flex;
  flex-direction: column;
  font-weight: 700;
  font-size: 2em;
  line-height: 25px;
}

.homepage .section .segment #tokenomics .container .right span{
  font-size: .9em;
  font-weight: 500;
}

@media (max-width: 600px) {
  .homepage .section .segment #tokenomics .container .right .head_txt{
    font-size: 1.5em;
    line-height: 28px;
  }
}

.homepage .section .segment #tokenomics .container .right .b_down{
  padding: 1em;
}

.homepage .section .segment #tokenomics .container .right .b_down .l_one{
  font-size: .9em;
  font-weight: 600;
  color: #3f3f3f;
  padding: 5px;
}

.homepage .section .segment #tokenomics .container .right button{
  background: none;
  text-transform: uppercase;
  cursor: pointer;
  font-size: .75em;
  font-weight: 700;
  color: #3f3f3f;
  padding: 5px;
  border: 1px solid #111f4d;
  border-radius: 5px;
  padding: 6.5px 15px;
}

@media(max-width:800px){

  .left{
    display:flex;
    flex-direction: column;
  }

  .right{
    display:flex;
    flex-direction: column;
  }
  #vision .container{
    display: flex;
    flex-direction: column-reverse;

  }

  #about .container {
    display: flex;
    flex-direction: column;
    padding: 0px;
  }

  .homepage .section .segment{
    margin: 45px auto;
    width: 100%;
  }

  .homepage .section .segment #vision .container .right{
    width: 80%;
    margin: 10px auto;
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex: 1;
    padding:10px;
  }

  .homepage .section .segment #vision .container .left{
    width: 80%;
    margin: 10px auto;
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex: 1;
    padding:10px;
  }


  .homepage .section .segment .container .right, .homepage .section .segment .container .left{
    padding: 3px 3px 3px 3px;
  }


  .homepage .section .intro_txt{
    font-size: .9em;
    width: 80%;
    margin: 10px auto;
    box-sizing: border-box;
    line-height: 25px;
    padding: 0px;
  }
 
  .homepage .section .btn_links{
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 0px;
  }

  .homepage .section .segment #vision .container .right .sub{
    color: #111f4d;
  font-size: 30px;
  padding: 0px;
  
  transform: rotate(0deg);
  text-transform: uppercase;
  font-weight: 700;
  
  }

  .homepage .section .segment #about .container .left .sub{
    color: #111f4d;
  font-size: 30px;
  padding: 0px;
  
  transform: rotate(0deg);
  text-transform: uppercase;
  font-weight: 700;
  
  }

  .homepage .section .segment .container .left .digit, .homepage .section .segment .container .right .digit{
    font-size: 30px;
    color: #111f4d;
    font-weight: 700;
  }


  .homepage .section .segment .container .left .wing img{
    height: 90%;
    max-width: 200px;
    max-height: 200px;
    width: 90%;
    margin: 0px auto;
  }

  .homepage .section .segment .left .head_txt{
    text-align: center;
    padding-bottom: 10px;
  }

  .homepage .section .segment .head_txt{
    color: #111f4d;
    font-size: 1.6em;
    font-weight: 700;
    text-align: center;
    
  }

  .homepage .section .segment .container .left .paragraph{
    color: #3f3f3f;
    font-weight: 600;
    font-size: .9em;
    line-height: 30px;
    text-align: center;
  }

  #about .left .sub{
    text-align: center;
  }

  #about .left .digit{
    text-align: center;
  }
#about .container{
  margin: 30px auto
}


.homepage .section .segment #about{
  display:flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  padding: 10px;
  box-sizing: border-box;
  width: 100%;

}

#about .right .txt{
  text-align: center;
  width: 80%;
  margin: 10px auto
}

#about .right .paragraph{
  text-align: center;
  margin: 10px auto
}

  .homepage .section #vision .segment .container .left .wing{
    display: block;
    width: 100%;
    margin: 10px auto;
  }


  .homepage .section .segment #utility .container .jumbo{
    padding: 10px;
    margin: 30px 0px;
    
  }


  
.homepage .section .segment #utility .container .jumbotron{
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  width: 90%;
  margin: 5px auto
}


.homepage .section .segment #utility .container .jumbotron .flanks .text, .homepage .section .segment #utility .container .jumbotron .center .text{
  color: #3f3f3f;
  font-weight: 600;
  font-size: .9em;
  line-height: 20px;
  width: 95%;
  margin: auto
}

.homepage .section .segment #utility .container .jumbotron .flanks{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid #132a75;
  border-radius: 5px;
  margin: 5px auto;
  padding: 30px 0;
}


.homepage .section .segment #utility .container .head{
  width: 90%;
  text-align: center;
}


.homepage .section .segment #utility .container .head .head_txt{
  padding-bottom: 5px;
  
}
 
.homepage .section .segment #utility .container .head .digit{
  font-size: 30px;
  color: #111f4d;
  font-weight: 700;
  text-align: center;
}

.homepage .section .segment #utility .container .head .sub{
  font-size: 30px;
  color: #111f4d;
  font-weight: 700;
  text-align: center;
}


.homepage .section .segment #utility .container .jumbotron .center{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid #132a75;
  border-radius: 5px;
  margin: 5px auto;
  padding: 30px 0;
}


.homepage .section .segment #utility .container .jumbotron .flanks .title, .homepage .section .segment #utility .container .jumbotron .center .title{
  text-align: center;
  font-size: 1.1em;
  color: #111f4d;
  font-weight: 600;
  padding: 20px 0;
  width: 80%;
}

.homepage .section .segment #roadmap .container{
  margin: 0 6em;
  flex-direction: column-reverse;
  justify-content: space-around;
  width: 85%;
  margin: 15px auto
}

.homepage .section .segment #roadmap{
  flex-direction: column;
  justify-content: space-around;
}

.homepage .section .segment #roadmap .map_cont{
  display: block;
  width: 85%;
  height: auto;
  margin: 10px auto;
  overflow: auto;
}

.homepage .section .segment #roadmap .map_cont img{
  width: 500px;
  height: 150px;
  object-fit: fill;
}

.homepage .section .segment #roadmap .container .right .sub{
  color: #111f4d;
  font-size: 30px;
  text-transform: uppercase;
  font-weight: 700;
  text-align: center;
}

.homepage .section .segment #roadmap .container .right .digit{
  color: #111f4d;
  font-size: 30px;
  text-transform: uppercase;
  font-weight: 700;
  text-align: center;
}


.homepage .section .segment #roadmap .container .left .paragraph{
  width: 85%;
  padding: 10px;
  margin: auto;
  text-align: center;

}

.homepage .section .segment #roadmap .container .left {
  padding: 0px;
  
}

.homepage .section .segment #roadmap .container .left div{
  padding: 0px;
}

.homepage .section .segment #roadmap .text_cont{
  margin: 10px auto;
  display: block;
  width: 85%;
  justify-content: flex-end;
}

.homepage .section .segment #roadmap .text_cont p{
  width: 100%;
  text-align: right;
  color: #3f3f3f;
  font-weight: 600;
  font-size: .9em;
  line-height: 30px;
  text-align: center;
}

.segment #tokenomics .container{
  display: flex;
  flex-direction: column;
}

.homepage .section .segment #tokenomics .container .left{
  display: block;
  padding: 0px;
  width: 100%
}


.homepage .section .segment #tokenomics .container .left .sub{
  color: #111f4d;
  font-size: 30px;
  text-transform: uppercase;
  font-weight: 700;
  text-align: center;
}

.homepage .section .segment #tokenomics .container .left .digit{
  color: #111f4d;
  font-size: 30px;
  text-transform: uppercase;
  font-weight: 700;
  text-align: center;
}

.homepage .section .segment #tokenomics .container .left .tkn_txt{
    width: 90%;
    margin: auto;
}


.homepage .section .segment #tokenomics .container .left .tkn_txt .paragraph{
  width: 85%;
  padding: 10px;
  margin: auto;
  text-align: center;

}

.homepage .section .segment #tokenomics .container .left .tkn_sub .paragraph{
  width: 85%;
  padding: 10px;
  margin: auto;
  margin-top: 15px;
  text-align: center;

}

.homepage .section .segment #tokenomics .container .coin{
  flex: block;
  padding: 0px;
  width: auto;
  height: auto;
  margin-top: 30px;
  text-align: center;
}

.homepage .section .segment #tokenomics .container .coin > img{
  height: 150px;
  width: auto;
}


.homepage .section .segment #utility{
  box-sizing: border-box;
  width: 100%;
  margin: 0px auto
}


.homepage .section .segment #about{
  box-sizing: border-box;
  width: 100%;
  margin: 0px auto;
}

.homepage .section .segment #vision{
  box-sizing: border-box;
  width: 100%;
  margin: 0px auto
}

.homepage .section .segment #tokenomics{
  box-sizing: border-box;
  width: 100%;
  margin: 0px auto
}

.homepage .section .segment #roadmap{
  box-sizing: border-box;
  width: 100%;
  margin: 0px auto
}

.homepage .section .intro{
  
  box-sizing: border-box;
  width: 100%;
  margin: 0px auto
}

.homepage{
  box-sizing: border-box;
  width: 100%;
  margin: auto
}

}

@media (max-width: 600px) {
  .homepage .section .segment #tokenomics .container .coin > img{
    height: 100px;
    width: auto;
  }
}
/* HOME PAGE END */

/* TIMELINE */

.map_cont .timeline {
  display: flex;
  flex-direction: row;
  overflow-x: auto;
}

.homepage .section .btn_links button.pinksale {
  background-color: #fdeaf1;
  color: #f95997;
}